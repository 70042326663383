import { z } from 'zod';

import { PresetNameEnum, PresetNames } from './presets';
import { CssVariablesSchema } from './theme-css-variables';
import { ParametersSchema } from './theme-parameters';

export const ThemeNameEnum = PresetNameEnum;
export type ThemeName = z.infer<typeof ThemeNameEnum>;

export const ThemeSchema = z.object({
  name: ThemeNameEnum,
  cssVariables: CssVariablesSchema,
  parameters: ParametersSchema,
});

export const ThemeTheLeap: Theme = {
  name: PresetNames.THE_LEAP,
  cssVariables: {
    textColor: 'hsl(12, 55%, 19%)',
    textDisplayFont: 'Tobias',
    textDisplaySize: '1.77rem',
    textDisplayWeight: '300',
    textTitleSize: '1.15rem',
    textTitleWeight: '400',
    textBodyFont: 'Favorit',
    textBodySize: '0.95rem',
    textBodyWeight: '300',
    textButtonSize: '1.03rem',
    textButtonWeight: '400',
    gapBanner: '15.04rem',
    gapSpacing: '0.66rem',
    bgBody: 'hsl(0, 0%, 88%)',
    bgPageSolid: 'hsl(340, 10%, 94%)',
    bgPageGradient: 'none',
    bgFooter: 'hsl(0, 0%, 100%)',
    badgePadding: '0.125rem 0.5rem',
    badgeRadius: '1rem',
    badgeBgColor: 'hsl(340, 9%, 94%)',
    badgeTextColor: 'hsl(12, 55%, 19%)',
    badgeTextSize: '0.875rem',
    badgeOutlineColor: 'hsl(0, 0%, 63%)',
    badgeBorderColor: 'transparent',
    badgeShadow: 'none',
    buttonPadding: '0.5rem 2rem',
    buttonRadius: '2rem',
    buttonBgColor: 'hsl(65, 93%, 65%)',
    buttonTextColor: 'hsl(12, 55%, 19%)',
    buttonOutlineColor: 'hsl(12, 55%, 19%)',
    buttonBorderColor: 'transparent',
    buttonShadow: '1px 1px 0px hsla(0,0%,0%,0.1)',
    cardPadding: '1rem',
    cardRadius: '0.5rem',
    cardBgColor: 'hsl(0, 0%, 98%)',
    cardTextColor: 'hsl(12, 55%, 19%)',
    cardOutlineColor: 'transparent',
    cardBorderColor: 'transparent',
    cardShadow: '1px 1px 0px hsla(0,0%,0%,0.1)',
    iconRadius: '1.5rem',
    iconBgColor: 'hsl(340, 9%, 94%)',
    iconTextColor: 'hsl(12, 55%, 19%)',
    iconOutlineColor: 'hsl(0, 0%, 63%)',
    iconShadow: '1px 1px 0px hsla(0,0%,0%,0.1)',
    imageOutlineColor: 'transparent',
    imageOutlineWidth: '2px',
    imageShadow: '1px 1px 10px hsla(0,0,0,0.1)',
    imageOverlayGradient: 'none',
    imageOverlaySolid: 'transparent',
  },
  parameters: {
    preset: PresetNames.THE_LEAP,
    layout: 'banner',
    colorVariation: 'Off white',
    spacing: 32,
    blend: 10,
    displayFont: 'Tobias',
    bodyFont: 'Favorit',
    style: 'leap',
    styleStrength: 25,
    bgStyle: 'solid',
    bgHue: 340,
    bgLum: 94,
    uiHue: 340,
    uiLum: 100,
    saturation: 10,
    fontColor: 'brown',
    borderRadius: 100,
    fontSize: 27,
    textStrength: 0,
  },
};

export type Theme = z.infer<typeof ThemeSchema>;
