import { z } from 'zod';

export const FontEnum = z.enum([
  'Arvo',
  'Barlow',
  'Bebas Neue',
  'Bitter',
  'Calistoga',
  'Capriola',
  'Courgette',
  'Crimson Text',
  'Crimson Pro',
  'DM Sans',
  'EB Garamond',
  'Favorit',
  'Fredoka One',
  'Gothic A1',
  'Hepta Slab',
  'IBM Plex Sans',
  'IBM Plex Serif',
  'Inconsolata',
  'Inter',
  'Karla',
  'Kite One',
  'Lato',
  'Libre Baskerville',
  'Lora',
  'Merriweather',
  'Montserrat',
  'Nanum Pen Script',
  'Noto Serif',
  'Nova Round',
  'Oswald',
  'Outfit',
  'Pacifico',
  'Playfair Display',
  'Poppins',
  'PT Serif',
  'Quicksand',
  'Raleway',
  'Roboto',
  'Roboto Slab',
  'Rock Salt',
  'Rubik',
  'Source Serif Pro',
  'Space Mono',
  'Space Grotesk',
  'Sometype Mono',

  'Special Elite',
  'Tobias',
  'Work Sans',
  'Zilla Slab',
]);

export const CssVariableKindEnum = z.enum([
  'color',
  'font',
  'background',
  'length',
]);
export type CssVariableKind = z.infer<typeof CssVariableKindEnum>;

export const CssVariablesSchema = z.object({
  textColor: z.string(),
  textDisplayFont: z.string(),
  textDisplaySize: z.string(),
  textDisplayWeight: z.string(),
  textTitleSize: z.string(),
  textTitleWeight: z.string(),
  textBodyFont: z.string(),
  textBodySize: z.string(),
  textBodyWeight: z.string(),
  textButtonSize: z.string(),
  textButtonWeight: z.string(),

  gapBanner: z.string(),
  gapSpacing: z.string(),

  bgPageSolid: z.string(),
  bgPageGradient: z.string(),
  bgBody: z.string(),
  bgFooter: z.string(),

  badgePadding: z.string(),
  badgeRadius: z.string(),
  badgeBgColor: z.string(),
  badgeTextColor: z.string(),
  badgeTextSize: z.string(),
  badgeOutlineColor: z.string(),
  badgeBorderColor: z.string(),
  badgeShadow: z.string(),

  iconRadius: z.string(),
  iconBgColor: z.string(),
  iconTextColor: z.string(),
  iconOutlineColor: z.string(),
  iconShadow: z.string(),

  buttonPadding: z.string(),
  buttonRadius: z.string(),
  buttonBgColor: z.string(),
  buttonTextColor: z.string(),
  buttonOutlineColor: z.string(),
  buttonBorderColor: z.string(),
  buttonShadow: z.string(),

  cardPadding: z.string(),
  cardRadius: z.string(),
  cardBgColor: z.string(),
  cardTextColor: z.string(),
  cardOutlineColor: z.string(),
  cardBorderColor: z.string(),
  cardShadow: z.string(),

  imageOverlayGradient: z.string(),
  imageOverlaySolid: z.string(),
  imageOutlineColor: z.string(),
  imageOutlineWidth: z.string(),
  imageShadow: z.string(),
});

export type CssVariables = z.infer<typeof CssVariablesSchema>;

export type CssVariableKey = keyof CssVariables;

export const defaultCssVariables: CssVariables = {
  textColor: 'hsl(0 0% 100%)',
  textDisplayFont: 'Arvo',
  textDisplaySize: '1.5rem',
  textDisplayWeight: 'normal',
  textTitleSize: '3rem',
  textTitleWeight: 'bold',
  textBodyFont: 'Arvo',
  textBodySize: '1.5rem',
  textBodyWeight: 'normal',
  textButtonSize: '1rem',
  textButtonWeight: 'bold',

  gapBanner: '3rem',
  gapSpacing: '2rem',

  bgPageSolid: 'hsl(0 0% 100%)',
  bgPageGradient: 'none',
  bgBody: 'hsl(0 0% 100%)',
  bgFooter: 'none',

  badgePadding: '0.5rem',
  badgeRadius: '0.5rem',
  badgeBgColor: '#F0EDEE',
  badgeTextColor: '#4C2116',
  badgeTextSize: '1rem',
  badgeOutlineColor: '#A1A1A1',
  badgeBorderColor: '#A1A1A1',
  badgeShadow: '0',

  iconRadius: '0.5rem',
  iconBgColor: 'transparent',
  iconTextColor: '#4C2116',
  iconOutlineColor: 'transparent',
  iconShadow: '0',

  buttonPadding: '0.5rem',
  buttonRadius: '0.5rem',
  buttonBgColor: '#F0EDEE',
  buttonTextColor: '#4C2116',
  buttonOutlineColor: '#A1A1A1',
  buttonBorderColor: '#A1A1A1',
  buttonShadow: '0',

  cardPadding: '0.5rem',
  cardRadius: '0.5rem',
  cardBgColor: '#F0EDEE',
  cardTextColor: '#4C2116',
  cardOutlineColor: '#A1A1A1',
  cardBorderColor: '#A1A1A1',
  cardShadow: '0',

  imageOverlayGradient: 'none',
  imageOverlaySolid: 'inherit',
  imageOutlineColor: 'transparent',
  imageOutlineWidth: '2px',
  imageShadow: 'none',
};
