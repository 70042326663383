import { z } from 'zod';

import { PresetNameEnum } from './presets';

export const LayoutEnum = z.enum(['stack', 'portrait', 'banner', 'compact']);
export const StyleEnum = z.enum([
  'leap',
  'raised',
  'box',
  'lined',
  'book',
  'flat',
]);

export const ParametersSchema = z.object({
  preset: PresetNameEnum,
  layout: LayoutEnum,
  colorVariation: z.string().optional(),
  spacing: z.number(),
  blend: z.number(),
  displayFont: z.string(),
  bodyFont: z.string(),
  style: StyleEnum,
  styleStrength: z.number(),
  bgStyle: z.string(),
  bgHue: z.number(),
  bgLum: z.number(),
  uiHue: z.number(),
  uiLum: z.number(),
  saturation: z.number(),
  fontColor: z.string(),
  borderRadius: z.number(),
  fontSize: z.number(),
  textStrength: z.number(),
});

export type Parameters = z.infer<typeof ParametersSchema>;
export type LayoutValue = z.infer<typeof LayoutEnum>;
